// import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
// import "./Restaurants.css";
// import config from '../../config';

// export default function AddCity() {
//     const [cities, setCities] = useState([]);
//     const [cityName, setCityName] = useState('');
//     const [areas, setAreas] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [newAreaName, setNewAreaName] = useState('');
//     const [newAreaDescription, setNewAreaDescription] = useState('');
//     const [selectedCity, setSelectedCity] = useState('');
//     const [selectedArea, setSelectedArea] = useState(null);
//     const [editAreaName, setEditAreaName] = useState('');
//     const [editAreaDescription, setEditAreaDescription] = useState('');
//     const { API_URL } = config;

//     const fetchCities = async () => {
//         try {
//             const response = await fetch(`${API_URL}/api/city/getAllCities`);
//             const data = await response.json();
//             console.log(data);
//             if (data.error) {
//                 alert(data.error);
//                 setCities([]);
//             } else {
//                 setCities(data);
//             }
//         } catch (error) {
//             console.error("Error fetching cities:", error);
//             alert("Error fetching cities");
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleAddCity = async () => {
//         if (cityName === '' ) {
//             alert("City name cannot be empty");     //this is a comment
//             return;
//         }

//         try {
//             const response = await fetch(`${API_URL}/api/city/addCity`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     cityName: cityName.charAt(0).toUpperCase() + cityName.slice(1).toLowerCase(),
//                     areas:[],
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("City added successfully");
//                 setCityName('');
//                 setAreas([]);
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to add city", error);
//             alert("Failed to add city");
//         }
//     };

//     const handleDeleteCity = async (cityName) => {
//         if (!window.confirm(`Are you sure you want to delete the city: ${cityName}?`)) return;
//         try {
//             const response = await fetch(`${API_URL}/api/city/deleteCity/${cityName}`, {
//                 method: 'DELETE',
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("City deleted successfully");
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to delete city", error);
//             alert("Failed to delete city");
//         }
//     };

//     const handleAddArea = async () => {
//         if (newAreaName === '' || newAreaDescription === '') {
//             alert("Area name and description cannot be empty");
//             return;
//         }
//         try {
//             const response = await fetch(`${API_URL}/api/city/addAreaByCity/${selectedCity}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     areaName: newAreaName.charAt(0).toUpperCase() + newAreaName.slice(1).toLowerCase(),
//                     description: newAreaDescription.charAt(0).toUpperCase() + newAreaDescription.slice(1).toLowerCase(),
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area added successfully");
//                 setNewAreaName('');
//                 setNewAreaDescription('');
//                 setSelectedCity('');
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to add area", error);
//             alert("Failed to add area");
//         }
//     };

//     const handleDeleteArea = async (cityName, areaName) => {
//         if (!window.confirm(`Are you sure you want to delete the area: ${areaName}?`)) return;
//         try {
//             const response = await fetch(`${API_URL}/api/city/deleteAreaByCity/${cityName}`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     areaName: areaName,
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area deleted successfully");
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to delete area", error);
//             alert("Failed to delete area");
//         }
//     };

//     const handleEditArea = async () => {
//         if (editAreaName === '' || editAreaDescription === '') {
//             alert("Area name and description cannot be empty");
//             return;
//         }
//         try {
//             const response = await fetch(`${API_URL}/api/city/updateDescription/${selectedCity}/${selectedArea}`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({

//                     description: editAreaDescription.charAt(0).toUpperCase() + editAreaDescription.slice(1).toLowerCase(),
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area updated successfully");
//                 setEditAreaName('');
//                 setEditAreaDescription('');
//                 setSelectedArea(null);
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to update area", error);
//             alert("Failed to update area");
//         }
//     };

//     useEffect(() => {
//         fetchCities();
//     }, []);

//     const filteredCities = cities.filter((city) =>
//         city.cityName.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: "100vh", maxWidth: "100vw", padding: "20px" }}>
//             <h1 className='fw-bold mb-4'>City Management</h1>

//             <div className="card shadow-sm mb-4" style={{ width: "90%"}}>
//                 <div className="card-body">
//                     <div className="d-flex justify-content-between align-items-center mb-3">
//                         <input
//                             type='text'
//                             className='form-control me-2'
//                             placeholder='Search by City Name...'
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             style={{ width: "70%" }}
//                         />
//                         <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCityModal">
//                             <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add City
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             <div className="card shadow-sm flex-grow-1" style={{ width: "90%",  overflow: "hidden" }}>
//                 <div className="card-body p-0">
//                     <div className="accordion" id="accordionExample" style={{ maxHeight: "calc(100vh - 250px)", overflowY: "auto" }}>
//                         {filteredCities.length === 0 ? (
//                             <p className="p-3">No cities found</p>
//                         ) : (
//                             filteredCities.map((citydata, index) => (
//                                 <div className="accordion-item" key={index}>
//                                     <h2 className="accordion-header d-flex justify-content-between align-items-center">
//                                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}>
//                                             {index + 1}. {citydata.cityName}
//                                         </button>
//                                         <div className='d-flex p-2'>
//                                             <button className="btn btn-primary btn-sm me-2 " style={{ width:"fit-content"}} data-bs-toggle="modal" data-bs-target="#addAreaModal" onClick={() => setSelectedCity(citydata.cityName)}>
//                                                 Add Area
//                                             </button>
//                                             <button className="btn btn-danger btn-sm" onClick={() => handleDeleteCity(citydata.cityName)}>
//                                                 <FontAwesomeIcon icon={faTrash} />
//                                             </button>
//                                         </div>
//                                     </h2>
//                                     <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
//                                         <div className="accordion-body">
//                                             <ul className="list-group">
//                                                 {citydata.areas && citydata.areas.length > 0 ? (
//                                                     citydata.areas.map((area, areaIndex) => (
//                                                         <li key={areaIndex} className="list-group-item d-flex justify-content-between align-items-center">
//                                                             <span>{area.areaName}: {area.description}</span>
//                                                             <div>
//                                                                 <button className="btn btn-warning btn-sm me-2" data-bs-toggle="modal" data-bs-target="#editAreaModal" onClick={() => {
//                                                                     setSelectedCity(citydata.cityName)
//                                                                     setSelectedArea(area.areaName);
//                                                                     setEditAreaName(area.areaName);
//                                                                     setEditAreaDescription(area.description);
//                                                                 }}>
//                                                                     <FontAwesomeIcon icon={faEdit} />
//                                                                 </button>
//                                                                 <button className="btn btn-danger btn-sm" onClick={() => handleDeleteArea(citydata.cityName, area.areaName)}>
//                                                                     <FontAwesomeIcon icon={faTrash} />
//                                                                 </button>
//                                                             </div>
//                                                         </li>
//                                                     ))
//                                                 ) : (
//                                                     <li className="list-group-item">No areas found for this city</li>
//                                                 )}
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))
//                         )}
//                     </div>
//                 </div>
//             </div>

//             {/* Modal for Adding City */}
//             <div className="modal fade" id="addCityModal" tabIndex="-1" aria-labelledby="addCityModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="addCityModalLabel">Add New City</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="cityNameInput" className="form-label">City Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="cityNameInput"
//                                     value={cityName}
//                                     onChange={(e) => setCityName(e.target.value)}
//                                 />
//                             </div>
//                             {/* <div className="mb-3">
//                                 <label htmlFor="areasInput" className="form-label">Areas (format: name: description, name: description)</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="areasInput"
//                                     value={areas.map(area => `${area.name}: ${area.description}`).join(', ')}
//                                     onChange={(e) => {
//                                         const areaArray = e.target.value.split(',').map(areaStr => {
//                                             const [name, description] = areaStr.split(':').map(part => part.trim());
//                                             return { name, description };
//                                         });
//                                         setAreas(areaArray);
//                                     }}
//                                 />
//                             </div> */}
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleAddCity}>Add City</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Modal for Adding Area */}
//             <div className="modal fade" id="addAreaModal" tabIndex="-1" aria-labelledby="addAreaModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="addAreaModalLabel">Add New Area</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="areaNameInput" className="form-label">Area Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="areaNameInput"
//                                     value={newAreaName}
//                                     onChange={(e) => setNewAreaName(e.target.value)}
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="areaDescriptionInput" className="form-label">Description</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="areaDescriptionInput"
//                                     value={newAreaDescription}
//                                     onChange={(e) => setNewAreaDescription(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleAddArea}>Add Area</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Modal for Editing Area */}
//             <div className="modal fade" id="editAreaModal" tabIndex="-1" aria-labelledby="editAreaModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="editAreaModalLabel">Edit Area</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="editAreaNameInput" className="form-label">Area Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     disabled
//                                     id="editAreaNameInput"
//                                     value={editAreaName}
//                                     onChange={(e) => setEditAreaName(e.target.value)}
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="editAreaDescriptionInput" className="form-label">Description</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="editAreaDescriptionInput"
//                                     value={editAreaDescription}
//                                     onChange={(e) => setEditAreaDescription(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleEditArea}>Save changes</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
// import "./Restaurants.css";
// import config from '../../config';
// import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import { db } from '../../repository/firebase_init';
// import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';


// export default function AddCity() {
//     const { API_URL } = config;
//     const [cities, setCities] = useState([]);
//     const [cityName, setCityName] = useState('');
//     const [areas, setAreas] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [newAreaName, setNewAreaName] = useState('');
//     const [newAreaDescription, setNewAreaDescription] = useState('');
//     const [selectedCity, setSelectedCity] = useState('');
//     const [selectedArea, setSelectedArea] = useState(null);
//     const [editAreaName, setEditAreaName] = useState('');
//     const [editAreaDescription, setEditAreaDescription] = useState('');
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [hubName, setHubName] = useState('');
//     const [latitude, setLatitude] = useState('');
//     const [longitude, setLongitude] = useState('');
//     const [hubs, setHubs] = useState({});
//     const [isHubsVisible, setIsHubsVisible] = useState(false);


//     const handleAddHub = async () => {
//         if (!selectedCity) {
//             alert("Please select a city first.");
//             return;
//         }

//         try {
//             const updatedHubs = { ...hubs };

//             if (!updatedHubs[selectedCity]) {
//                 updatedHubs[selectedCity] = [];
//             }

//             const cityInLowerCase = selectedCity.toLowerCase();

//             let hubData = {
//                 city: cityInLowerCase,
//                 hubId: hubName.toLowerCase(),
//                 lat: latitude,
//                 lng: longitude,
//             };

//             updatedHubs[cityInLowerCase] = updatedHubs[cityInLowerCase] || [];
//             updatedHubs[cityInLowerCase].push(hubData);

//             const hubDocRef = doc(collection(db, 'hubs'), 'metadata');
//             await updateDoc(hubDocRef, {
//                 [`${cityInLowerCase}.${hubName.toLowerCase()}`]: hubData,
//             });

//             hubData.queue = [];
//             const ref2 = doc(collection(db, 'hubs'), `${hubName.toLowerCase()}`);
//             await setDoc(ref2, hubData);

//             setHubs(updatedHubs);
//             setHubName('');
//             setLatitude('');
//             setLongitude('');
//             setIsModalOpen(false);

//             console.log("Hub added successfully");
//         } catch (error) {
//             console.error("Error adding hub:", error);
//             alert("Failed to add hub. Please try again.");
//         }
//     };


//     const handleShowHubs = async (cityName) => {
//         if (!cityName) {
//             alert("Please select a city first.");
//             return;
//         }

//         setSelectedCity(cityName);

//         try {
//             const cityInLowerCase = cityName.toLowerCase();

//             const hubDocRef = doc(collection(db, 'hubs'), 'metadata');
//             const docSnapshot = await getDoc(hubDocRef);

//             if (docSnapshot.exists()) {
//                 const hubsData = docSnapshot.data();
//                 const cityHubs = hubsData[cityInLowerCase] || {};

//                 const cityHubsArray = Object.values(cityHubs);

//                 setHubs((prev) => ({
//                     ...prev,
//                     [cityInLowerCase]: cityHubsArray,
//                 }));
//             } else {
//                 console.warn(`No hubs found for city: ${cityName}`);
//             }

//             setIsHubsVisible((prev) => !prev);

//         } catch (error) {
//             console.error("Error fetching hubs:", error);
//             alert("Failed to fetch hubs. Please try again.");
//         }
//     };

//     const fetchCities = async () => {
//         try {
//             const response = await fetch(`${API_URL}/api/city/getAllCities`);
//             const data = await response.json();
//             console.log(data);
//             if (data.error) {
//                 alert(data.error);
//                 setCities([]);
//             } else {
//                 setCities(data);
//             }
//         } catch (error) {
//             console.error("Error fetching cities:", error);
//             alert("Error fetching cities");
//         } finally {
//             setLoading(false);
//         }
//     };

//     const MapClickHandler = () => {
//         useMapEvents({
//             click(e) {
//                 setLatitude(e.latlng.lat);
//                 setLongitude(e.latlng.lng);
//             },
//         });
//         return null;
//     };


//     const handleAddCity = async () => {
//         if (cityName === '') {
//             alert("City name cannot be empty");
//             return;
//         }

//         try {
//             const response = await fetch(`${API_URL}/api/city/addCity`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     cityName: cityName.charAt(0).toUpperCase() + cityName.slice(1).toLowerCase(),
//                     areas: [],
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("City added successfully");
//                 setCityName('');
//                 setAreas([]);
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to add city", error);
//             alert("Failed to add city");
//         }
//     };

//     const handleDeleteCity = async (cityName) => {
//         if (!window.confirm(`Are you sure you want to delete the city: ${cityName}?`)) return;
//         try {
//             const response = await fetch(`${API_URL}/api/city/deleteCity/${cityName}`, {
//                 method: 'DELETE',
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("City deleted successfully");
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to delete city", error);
//             alert("Failed to delete city");
//         }
//     };

//     const handleAddArea = async () => {
//         if (newAreaName === '' || newAreaDescription === '') {
//             alert("Area name and description cannot be empty");
//             return;
//         }
//         try {
//             const response = await fetch(`${API_URL}/api/city/addAreaByCity/${selectedCity}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     areaName: newAreaName.charAt(0).toUpperCase() + newAreaName.slice(1).toLowerCase(),
//                     description: newAreaDescription.charAt(0).toUpperCase() + newAreaDescription.slice(1).toLowerCase(),
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area added successfully");
//                 setNewAreaName('');
//                 setNewAreaDescription('');
//                 setSelectedCity('');
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to add area", error);
//             alert("Failed to add area");
//         }
//     };

//     const handleDeleteArea = async (cityName, areaName) => {
//         if (!window.confirm(`Are you sure you want to delete the area: ${areaName}?`)) return;
//         try {
//             const response = await fetch(`${API_URL}/api/city/deleteAreaByCity/${cityName}`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     areaName: areaName,
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area deleted successfully");
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to delete area", error);
//             alert("Failed to delete area");
//         }
//     };

//     const handleEditArea = async () => {
//         if (editAreaName === '' || editAreaDescription === '') {
//             alert("Area name and description cannot be empty");
//             return;
//         }
//         try {
//             const response = await fetch(`${API_URL}/api/city/updateDescription/${selectedCity}/${selectedArea}`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({

//                     description: editAreaDescription.charAt(0).toUpperCase() + editAreaDescription.slice(1).toLowerCase(),
//                 }),
//             });
//             const data = await response.json();
//             if (data.error) {
//                 alert(data.error);
//             } else {
//                 alert("Area updated successfully");
//                 setEditAreaName('');
//                 setEditAreaDescription('');
//                 setSelectedArea(null);
//                 fetchCities();
//             }
//         } catch (error) {
//             console.error("Failed to update area", error);
//             alert("Failed to update area");
//         }
//     };

//     useEffect(() => {
//         fetchCities();
//     }, []);

//     const filteredCities = cities.filter((city) =>
//         city.cityName.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='p-6'>
//             <h1 className="text-2xl font-bold mb-4">City Management</h1>

//             <div className="flex justify-between items-center mb-3">
//                 <input
//                     type="text"
//                     className="border rounded-lg px-3 py-2 placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-7/10"
//                     placeholder="Search by City Name..."
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//                 <button
//                     type="button"
//                     className="border border-[#BACAF4] text-[#0F60FF] text-[15px] leading-[17.63px] rounded-[7px] font-medium py-2 px-4 flex justify-center items-center hover:bg-blue-100 hover:font-semibold"
//                     data-bs-toggle="modal"
//                     data-bs-target="#addCityModal"
//                 >
//                     Add City
//                     <FontAwesomeIcon icon={faPlusCircle} className="ml-2 text-[#04CF31] w-7 h-7" />
//                 </button>
//             </div>

//             <div className='flex flex-wrap justify-start gap-5'>
//                 {filteredCities.length === 0 ? (
//                     <p className="p-3">No cities found</p>
//                 ) : (
//                     filteredCities.map((citydata, index) => (
//                         <div key={index} className="w-[30%] rounded-[25px] h-auto bg-blue-50">
//                             <div className='flex justify-between items-center px-4 py-5'>
//                                 <h3 className='uppercase text-[19.64px] text-[#060101] leading-[23.8px] font-medium'>
//                                     {citydata.cityName}
//                                 </h3>
//                                 <button
//                                     className="btn btn-danger btn-sm"
//                                     onClick={() => handleDeleteCity(citydata.cityName)}
//                                 >
//                                     <FontAwesomeIcon icon={faTrash} />
//                                 </button>
//                             </div>
//                             <div className='px-3 flex justify-evenly gap-3 mb-3'>
//                                 <button
//                                     className='w-1/2 bg-[#0F60FF] text-white p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px] hover:bg-blue-500'
//                                     data-bs-toggle="modal"
//                                     data-bs-target="#addAreaModal"
//                                     onClick={() => setSelectedCity(citydata.cityName)}
//                                 >
//                                     Add Area
//                                 </button>
//                                 <button
//                                     className='w-1/2 border-4 border-[#ECF3FF] hover:bg-blue-200 text-[#0F60FF] p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px]'
//                                     data-bs-toggle="modal"
//                                     data-bs-target="#viewCityModal"
//                                     onClick={() => setSelectedCity(citydata)}
//                                 >
//                                     Show Areas
//                                 </button>
//                             </div>

//                             <div className='px-3 flex justify-evenly gap-3 mb-3'>
//                                 <button
//                                     className='w-1/2 bg-[#0F60FF] text-white p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px] hover:bg-blue-500'
//                                     onClick={() => {
//                                         setSelectedCity(citydata.cityName);
//                                         setIsModalOpen(true);
//                                     }}
//                                 >
//                                     Add Hubs
//                                 </button>
//                                 <button
//                                     className="w-1/2 border-4 border-[#ECF3FF] hover:bg-blue-200 text-[#0F60FF] p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px]"
//                                     onClick={() => handleShowHubs(citydata.cityName)}
//                                 >
//                                     {/* {isHubsVisible ? "Hide Hubs" : "Show Hubs"} */}
//                                     Show Hubs
//                                 </button>
//                             </div>
//                         </div>
//                     ))
//                 )}


//                 {isModalOpen && selectedCity && (
//                     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//                         <div className="bg-white p-6 rounded-lg w-[90vw] h-[90vh]">
//                             <h2 className="text-lg font-bold mb-3">Add Hub for {selectedCity}</h2>
//                             <div className='flex justify-evenly'>
//                                 <div>
//                                     <label className="block font-semibold mb-1">Hub Name</label>
//                                     <input
//                                         type="text"
//                                         className="w-full border rounded p-2 mb-2"
//                                         value={hubName}
//                                         onChange={(e) => setHubName(e.target.value)}
//                                         placeholder="Enter Hub Name"
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block font-semibold mb-1">Latitude</label>
//                                     <input
//                                         type="text"
//                                         className="w-full border rounded p-2 mb-2"
//                                         value={latitude}
//                                         readOnly
//                                         placeholder="Click on the map to autofill"
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block font-semibold mb-1">Longitude</label>
//                                     <input
//                                         type="text"
//                                         className="w-full border rounded p-2 mb-2"
//                                         value={longitude}
//                                         readOnly
//                                         placeholder="Click on the map to autofill"
//                                     />
//                                 </div>
//                             </div>

//                             <MapContainer
//                                 center={[29.916781197718766, 78.06355020472472]}
//                                 zoom={13}
//                                 style={{ height: '65%', width: '100%' }}
//                             >
//                                 <TileLayer
//                                     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                                 />
//                                 <MapClickHandler />
//                                 {latitude && longitude && <Marker position={[latitude, longitude]} />}
//                             </MapContainer>

//                             <div className="mt-4 flex justify-end">
//                                 <button
//                                     className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
//                                     onClick={() => setIsModalOpen(false)}
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button
//                                     className="bg-blue-500 text-white px-4 py-2 rounded"
//                                     onClick={handleAddHub}
//                                 >
//                                     Save
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 {isHubsVisible && selectedCity && (
//                     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                         <div className="bg-white rounded-lg p-6 w-96">
//                             <h2 className="text-lg font-bold mb-4">Hubs in {selectedCity}</h2>
//                             <ul className="border border-gray-200 rounded p-4">
//                                 {hubs[selectedCity.toLowerCase()]?.length > 0 ? (
//                                     hubs[selectedCity.toLowerCase()].map((hub, index) => (
//                                         <li key={index} className="mb-2 p-2 bg-gray-50 rounded shadow">
//                                             <p>
//                                                 <strong>Hub Name:</strong> {hub.hubId}
//                                             </p>
//                                             <p>
//                                                 <strong>Latitude:</strong> {hub.lat}
//                                             </p>
//                                             <p>
//                                                 <strong>Longitude:</strong> {hub.lng}
//                                             </p>
//                                             {hub.queue && (
//                                                 <p>
//                                                     <strong>Queue:</strong> {hub.queue.join(", ")}
//                                                 </p>
//                                             )}
//                                         </li>
//                                     ))
//                                 ) : (
//                                     <p>No hubs available for this city.</p>
//                                 )}
//                             </ul>
//                             <div className="flex justify-end mt-4">
//                                 <button
//                                     className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
//                                     onClick={() => setIsHubsVisible(false)}
//                                 >
//                                     Close
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 <div
//                     className="modal fade"
//                     id="viewCityModal"
//                     tabIndex="-1"
//                     aria-labelledby="viewCityModalLabel"
//                     aria-hidden="true"
//                 >
//                     <div className="modal-dialog modal-lg">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id="viewCityModalLabel">
//                                     Areas in {selectedCity?.cityName}
//                                 </h5>
//                                 <button
//                                     type="button"
//                                     className="btn-close"
//                                     data-bs-dismiss="modal"
//                                     aria-label="Close"
//                                 ></button>
//                             </div>
//                             <div className="modal-body">
//                                 {selectedCity?.areas && selectedCity.areas.length > 0 ? (
//                                     <ul className="list-group">
//                                         {selectedCity.areas.map((area, areaIndex) => (
//                                             <li
//                                                 key={areaIndex}
//                                                 className="list-group-item d-flex justify-content-between align-items-center"
//                                             >
//                                                 <span>{area.areaName}: {area.description}</span>
//                                                 <div>
//                                                     <button
//                                                         className="btn btn-warning btn-sm me-2"
//                                                         data-bs-toggle="modal"
//                                                         data-bs-target="#editAreaModal"
//                                                         onClick={() => {
//                                                             setSelectedArea(area.areaName);
//                                                             setEditAreaName(area.areaName);
//                                                             setEditAreaDescription(area.description);
//                                                         }}
//                                                     >
//                                                         <FontAwesomeIcon icon={faEdit} />
//                                                     </button>
//                                                     <button
//                                                         className="btn btn-danger btn-sm"
//                                                         onClick={() => handleDeleteArea(selectedCity.cityName, area.areaName)}
//                                                     >
//                                                         <FontAwesomeIcon icon={faTrash} />
//                                                     </button>
//                                                 </div>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 ) : (
//                                     <p>No areas found for this city.</p>
//                                 )}
//                             </div>
//                             <div className="modal-footer">
//                                 <button
//                                     type="button"
//                                     className="btn btn-secondary"
//                                     data-bs-dismiss="modal"
//                                 >
//                                     Close
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="modal fade" id="addCityModal" tabIndex="-1" aria-labelledby="addCityModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="addCityModalLabel">Add New City</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="cityNameInput" className="form-label">City Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="cityNameInput"
//                                     value={cityName}
//                                     onChange={(e) => setCityName(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleAddCity}>Add City</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="modal fade" id="addAreaModal" tabIndex="-1" aria-labelledby="addAreaModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="addAreaModalLabel">Add New Area</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="areaNameInput" className="form-label">Area Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="areaNameInput"
//                                     value={newAreaName}
//                                     onChange={(e) => setNewAreaName(e.target.value)}
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="areaDescriptionInput" className="form-label">Description</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="areaDescriptionInput"
//                                     value={newAreaDescription}
//                                     onChange={(e) => setNewAreaDescription(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleAddArea}>Add Area</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="modal fade" id="editAreaModal" tabIndex="-1" aria-labelledby="editAreaModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="editAreaModalLabel">Edit Area</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-3">
//                                 <label htmlFor="editAreaNameInput" className="form-label">Area Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     disabled
//                                     id="editAreaNameInput"
//                                     value={editAreaName}
//                                     onChange={(e) => setEditAreaName(e.target.value)}
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="editAreaDescriptionInput" className="form-label">Description</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="editAreaDescriptionInput"
//                                     value={editAreaDescription}
//                                     onChange={(e) => setEditAreaDescription(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-primary" onClick={handleEditArea}>Save changes</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import "./Restaurants.css";
import config from '../../config';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { db } from '../../repository/firebase_init';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import pin from '../../Images/pin.png'
import L from 'leaflet';


export default function AddCity() {
    const { API_URL } = config;
    const [cities, setCities] = useState([]);
    const [cityName, setCityName] = useState('');
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [newAreaName, setNewAreaName] = useState('');
    const [newAreaDescription, setNewAreaDescription] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedArea, setSelectedArea] = useState(null);
    const [editAreaName, setEditAreaName] = useState('');
    const [editAreaDescription, setEditAreaDescription] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hubName, setHubName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [hubs, setHubs] = useState({});
    const [isHubsVisible, setIsHubsVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedHub, setSelectedHub] = useState(null);
    const [editedLat, setEditedLat] = useState('');
    const [editedLng, setEditedLng] = useState('');

    const handleEditHub = (hub) => {
        setSelectedHub(hub);
        setEditedLat(hub.lat);
        setEditedLng(hub.lng);
        setIsEditModalVisible(true);
        setIsHubsVisible(false)

    };

    const MapClickHandler = ({ setEditedLat, setEditedLng, setLatitude, setLongitude }) => {
        useMapEvents({
            click: (e) => {
                if (setEditedLat && setEditedLng) {
                    setEditedLat(e.latlng.lat.toFixed(6));
                    setEditedLng(e.latlng.lng.toFixed(6));
                }
                if (setLatitude && setLongitude) {
                    setLatitude(e.latlng.lat.toFixed(6));
                    setLongitude(e.latlng.lng.toFixed(6));
                }
            },
        });
        return null;
    };


    const handleAddHub = async () => {
        if (!selectedCity) {
            alert("Please select a city first.");
            return;
        }

        try {
            const updatedHubs = { ...hubs };
            const cityInLowerCase = selectedCity.toLowerCase();

            let hubData = {
                city: cityInLowerCase,
                hubId: selectedHub ? selectedHub.hubId : hubName.toLowerCase(),
                lat: editedLat || latitude,
                lng: editedLng || longitude,
            };

            if (selectedHub) {
                const hubIndex = updatedHubs[cityInLowerCase]?.findIndex(
                    (hub) => hub.hubId === selectedHub.hubId
                );

                if (hubIndex !== -1) {
                    updatedHubs[cityInLowerCase][hubIndex] = hubData;

                    const hubDocRef = doc(collection(db, 'hubs'), 'metadata');
                    await updateDoc(hubDocRef, {
                        [`${cityInLowerCase}.${selectedHub.hubId}`]: hubData,
                    });

                    const ref2 = doc(collection(db, 'hubs'), selectedHub.hubId);
                    await updateDoc(ref2, hubData);

                    console.log("Hub updated successfully");
                }
            } else {
                if (!updatedHubs[cityInLowerCase]) {
                    updatedHubs[cityInLowerCase] = [];
                }
                updatedHubs[cityInLowerCase].push(hubData);

                const hubDocRef = doc(collection(db, 'hubs'), 'metadata');
                await updateDoc(hubDocRef, {
                    [`${cityInLowerCase}.${hubName.toLowerCase()}`]: hubData,
                });

                hubData.queue = [];
                const ref2 = doc(collection(db, 'hubs'), hubName.toLowerCase());
                await setDoc(ref2, hubData);

                console.log("Hub added successfully");
            }

            setHubs(updatedHubs);
            setHubName('');
            setLatitude('');
            setLongitude('');
            setEditedLat('');
            setEditedLng('');
            setSelectedHub(null);
            setIsModalOpen(false);
            setIsEditModalVisible(false);
        } catch (error) {
            console.error("Error saving hub:", error);
            alert("Failed to save hub. Please try again.");
        }
    };


    const handleShowHubs = async (cityName) => {
        if (!cityName) {
            alert("Please select a city first.");
            return;
        }

        setSelectedCity(cityName);

        try {
            const cityInLowerCase = cityName.toLowerCase();

            const hubDocRef = doc(collection(db, 'hubs'), 'metadata');
            const docSnapshot = await getDoc(hubDocRef);

            if (docSnapshot.exists()) {
                const hubsData = docSnapshot.data();
                const cityHubs = hubsData[cityInLowerCase] || {};

                const cityHubsArray = Object.values(cityHubs);

                setHubs((prev) => ({
                    ...prev,
                    [cityInLowerCase]: cityHubsArray,
                }));
            } else {
                console.warn(`No hubs found for city: ${cityName}`);
            }

            setIsHubsVisible((prev) => !prev);

        } catch (error) {
            console.error("Error fetching hubs:", error);
            alert("Failed to fetch hubs. Please try again.");
        }
    };

    const fetchCities = async () => {
        try {
            const response = await fetch(`${API_URL}/api/city/getAllCities`);
            const data = await response.json();
            console.log(data);
            if (data.error) {
                alert(data.error);
                setCities([]);
            } else {
                setCities(data);
            }
        } catch (error) {
            console.error("Error fetching cities:", error);
            alert("Error fetching cities");
        } finally {
            setLoading(false);
        }
    };

    const handleAddCity = async () => {
        if (cityName === '') {
            alert("City name cannot be empty");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/city/addCity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cityName: cityName.charAt(0).toUpperCase() + cityName.slice(1).toLowerCase(),
                    areas: [],
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("City added successfully");
                setCityName('');
                setAreas([]);
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to add city", error);
            alert("Failed to add city");
        }
    };

    const handleDeleteCity = async (cityName) => {
        if (!window.confirm(`Are you sure you want to delete the city: ${cityName}?`)) return;
        try {
            const response = await fetch(`${API_URL}/api/city/deleteCity/${cityName}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("City deleted successfully");
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to delete city", error);
            alert("Failed to delete city");
        }
    };

    const handleAddArea = async () => {
        if (newAreaName === '' || newAreaDescription === '') {
            alert("Area name and description cannot be empty");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/api/city/addAreaByCity/${selectedCity}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    areaName: newAreaName.charAt(0).toUpperCase() + newAreaName.slice(1).toLowerCase(),
                    description: newAreaDescription.charAt(0).toUpperCase() + newAreaDescription.slice(1).toLowerCase(),
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area added successfully");
                setNewAreaName('');
                setNewAreaDescription('');
                setSelectedCity('');
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to add area", error);
            alert("Failed to add area");
        }
    };

    const handleDeleteArea = async (cityName, areaName) => {
        if (!window.confirm(`Are you sure you want to delete the area: ${areaName}?`)) return;
        try {
            const response = await fetch(`${API_URL}/api/city/deleteAreaByCity/${cityName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    areaName: areaName,
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area deleted successfully");
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to delete area", error);
            alert("Failed to delete area");
        }
    };

    const handleEditArea = async () => {
        if (editAreaName === '' || editAreaDescription === '') {
            alert("Area name and description cannot be empty");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/api/city/updateDescription/${selectedCity.cityName}/${selectedArea}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({

                    description: editAreaDescription.charAt(0).toUpperCase() + editAreaDescription.slice(1).toLowerCase(),
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area updated successfully");
                setEditAreaName('');
                setEditAreaDescription('');
                setSelectedArea(null);
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to update area", error);
            alert("Failed to update area");
        }
    };

    useEffect(() => {
        fetchCities();
    }, []);

    const filteredCities = cities.filter((city) =>
        city.cityName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <div className='d-flex flex-column ' style={{ height: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    const customIcon = new L.Icon({
        iconUrl: pin,
        iconSize: [32, 32], // Size of the icon [width, height]
        iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
        popupAnchor: [0, -32], // Point from which the popup should open relative to the iconAnchor
    });

    return (
        <div className='p-6'>
            <h1 className="text-2xl font-bold mb-4">City Management</h1>

            <div className="flex justify-between items-center mb-3">
                <input
                    type="text"
                    className="border rounded-lg px-3 py-2 placeholder-gray-400 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-7/10"
                    placeholder="Search by City Name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                    type="button"
                    className="border border-[#BACAF4] text-[#0F60FF] text-[15px] leading-[17.63px] rounded-[7px] font-medium py-2 px-4 flex justify-center items-center hover:bg-blue-100 hover:font-semibold"
                    data-bs-toggle="modal"
                    data-bs-target="#addCityModal"
                >
                    Add City
                    <FontAwesomeIcon icon={faPlusCircle} className="ml-2 text-[#04CF31] w-7 h-7" />
                </button>
            </div>

            <div className='flex flex-wrap justify-start gap-5'>
                {filteredCities.length === 0 ? (
                    <p className="p-3">No cities found</p>
                ) : (
                    filteredCities.map((citydata, index) => (
                        <div key={index} className="w-[30%] rounded-[25px] h-auto bg-blue-50">
                            <div className='flex justify-between items-center px-4 py-5'>
                                <h3 className='uppercase text-[19.64px] text-[#060101] leading-[23.8px] font-medium'>
                                    {citydata.cityName}
                                </h3>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDeleteCity(citydata.cityName)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                            <div className='px-3 flex justify-evenly gap-3 mb-3'>
                                <button
                                    className='w-1/2 bg-[#0F60FF] text-white p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px] hover:bg-blue-500'
                                    data-bs-toggle="modal"
                                    data-bs-target="#addAreaModal"
                                    onClick={() => setSelectedCity(citydata.cityName)}
                                >
                                    Add Area
                                </button>
                                <button
                                    className='w-1/2 border-4 border-[#ECF3FF] hover:bg-blue-200 text-[#0F60FF] p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px]'
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewCityModal"
                                    onClick={() => setSelectedCity(citydata)}
                                >
                                    Show Areas
                                </button>
                            </div>

                            <div className='px-3 flex justify-evenly gap-3 mb-3'>
                                <button
                                    className='w-1/2 bg-[#0F60FF] text-white p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px] hover:bg-blue-500'
                                    onClick={() => {
                                        setSelectedCity(citydata.cityName);
                                        setIsModalOpen(true);
                                    }}
                                >
                                    Add Hubs
                                </button>
                                <button
                                    className="w-1/2 border-4 border-[#ECF3FF] hover:bg-blue-200 text-[#0F60FF] p-2 rounded-[7px] uppercase font-semibold text-[14.46px] leading-[23px]"
                                    onClick={() => handleShowHubs(citydata.cityName)}
                                >
                                    Show Hubs
                                </button>
                            </div>
                        </div>
                    ))
                )}

                {isModalOpen && selectedCity && (

                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg w-[90vw] h-[90vh]">
                            <h2 className="text-lg font-bold mb-3">Add Hub for {selectedCity}</h2>
                            <div className='flex justify-evenly mb-2'>
                                <div>
                                    <label className="block font-semibold mb-1">Hub Name</label>
                                    <input
                                        type="text"
                                        className="w-full border rounded p-2 mb-2"
                                        value={hubName}
                                        onChange={(e) => setHubName(e.target.value)}
                                        placeholder="Enter Hub Name"
                                    />
                                </div>
                                <div>
                                    <label className="block font-semibold mb-1">Latitude</label>
                                    <input
                                        type="text"
                                        className="w-full border rounded p-2 mb-2"
                                        value={latitude}
                                        readOnly
                                        placeholder="Click on the map to autofill"
                                    />
                                </div>
                                <div>
                                    <label className="block font-semibold mb-1">Longitude</label>
                                    <input
                                        type="text"
                                        className="w-full border rounded p-2 mb-2"
                                        value={longitude}
                                        readOnly
                                        placeholder="Click on the map to autofill"
                                    />
                                </div>
                            </div>
                            <label className="block font-semibold mb-1">Select Location</label>
                            <MapContainer
                                center={[latitude || 29.916515, longitude || 78.063915]}
                                zoom={13}
                                style={{ height: "350px", width: "100%" }}
                            >
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                <MapClickHandler setLatitude={setLatitude} setLongitude={setLongitude} />
                                {latitude && longitude && <Marker icon={customIcon} position={[latitude, longitude]} />}
                            </MapContainer>


                            <div className="mt-4 flex justify-end">
                                <button
                                    className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={handleAddHub}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isHubsVisible && selectedCity && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white rounded-lg p-6 w-[40%] max-h-[90vh]">
                            <h2 className="text-lg font-bold mb-4">Hubs in {selectedCity}</h2>
                            <ul className="border border-gray-200 rounded p-4 max-h-[65vh] overflow-y-auto">
                                {hubs[selectedCity.toLowerCase()]?.length > 0 ? (
                                    hubs[selectedCity.toLowerCase()].map((hub, index) => (
                                        <li
                                            key={index}
                                            className="mb-2 p-2 bg-gray-50 rounded shadow flex items-center justify-between"
                                        >
                                            <div>
                                                <p>
                                                    <strong>Hub Name:</strong> {hub.hubId}
                                                </p>
                                                <p>
                                                    <strong>Latitude:</strong> {hub.lat}
                                                </p>
                                                <p>
                                                    <strong>Longitude:</strong> {hub.lng}
                                                </p>
                                                {hub.queue && (
                                                    <p>
                                                        <strong>Queue:</strong> {hub.queue.join(", ")}
                                                    </p>
                                                )}
                                            </div>
                                            <button
                                                className="text-blue-500 hover:text-blue-700"
                                                onClick={() => handleEditHub(hub)}
                                            >
                                                ✏️
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <p>No hubs available for this city.</p>
                                )}
                            </ul>
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                                    onClick={() => setIsHubsVisible(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isEditModalVisible && selectedHub && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg w-[90vw] h-[90vh]">
                            <h2 className="text-lg font-bold mb-4">Edit Hub Details</h2>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleAddHub();
                                }}
                            >
                                <div className='flex justify-evenly'>
                                    <div className="mb-3">
                                        <label className="block text-sm font-bold text-gray-700">
                                            Hub Name
                                        </label>
                                        <input
                                            type="text"
                                            value={selectedHub.hubId}
                                            disabled
                                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none bg-gray-100"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="block text-sm font-bold text-gray-700">
                                            Latitude
                                        </label>
                                        <input
                                            type="text"
                                            value={editedLat}
                                            onChange={(e) => setEditedLat(e.target.value)}
                                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="block text-sm font-bold text-gray-700">
                                            Longitude
                                        </label>
                                        <input
                                            type="text"
                                            value={editedLng}
                                            onChange={(e) => setEditedLng(e.target.value)}
                                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-4">
                                        <h3 className="text-sm font-bold text-gray-700 mb-1">Select Location</h3>
                                        <MapContainer
                                            center={[editedLat || 29.916515, editedLng || 78.063915]}
                                            zoom={13}
                                            style={{ height: "350px", width: "100%" }}
                                        >
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            <MapClickHandler setEditedLat={setEditedLat} setEditedLng={setEditedLng} />
                                            {editedLat && editedLng && <Marker icon={customIcon} position={[editedLat, editedLng]} />}
                                        </MapContainer>

                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 mr-2"
                                            onClick={() => setIsEditModalVisible(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                )}

                <div
                    className="modal fade"
                    id="viewCityModal"
                    tabIndex="-1"
                    aria-labelledby="viewCityModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="viewCityModalLabel">
                                    Areas in {selectedCity?.cityName}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {selectedCity?.areas && selectedCity.areas.length > 0 ? (
                                    <ul className="list-group">
                                        {selectedCity.areas.map((area, areaIndex) => (
                                            <li
                                                key={areaIndex}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                <span>{area.areaName}: {area.description}</span>
                                                <div>
                                                    <button
                                                        className="btn btn-warning btn-sm me-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editAreaModal"
                                                        onClick={() => {
                                                            setSelectedArea(area.areaName);
                                                            setEditAreaName(area.areaName);
                                                            setEditAreaDescription(area.description);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteArea(selectedCity.cityName, area.areaName)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No areas found for this city.</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addCityModal" tabIndex="-1" aria-labelledby="addCityModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCityModalLabel">Add New City</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="cityNameInput" className="form-label">City Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cityNameInput"
                                    value={cityName}
                                    onChange={(e) => setCityName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddCity}>Add City</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addAreaModal" tabIndex="-1" aria-labelledby="addAreaModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAreaModalLabel">Add New Area</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="areaNameInput" className="form-label">Area Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="areaNameInput"
                                    value={newAreaName}
                                    onChange={(e) => setNewAreaName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="areaDescriptionInput" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="areaDescriptionInput"
                                    value={newAreaDescription}
                                    onChange={(e) => setNewAreaDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddArea}>Add Area</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="editAreaModal" tabIndex="-1" aria-labelledby="editAreaModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editAreaModalLabel">Edit Area</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="editAreaNameInput" className="form-label">Area Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    id="editAreaNameInput"
                                    value={editAreaName}
                                    onChange={(e) => setEditAreaName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editAreaDescriptionInput" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="editAreaDescriptionInput"
                                    value={editAreaDescription}
                                    onChange={(e) => setEditAreaDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleEditArea}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
