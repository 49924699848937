// import React, { useState } from 'react';
// import './ScreenCss/auth.css';
// import config from '../config';
// import { useNavigate } from 'react-router-dom';
// import image from "../Images/Bg.png"
// import logo from "../Images/logo.png"
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../repository/firebase_init'

// const backgroundContainerStyle = {
//     backgroundImage: `url(${image})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     minHeight: '100vh',
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
// };

// function SigninAdmin() {
//     const [passwordVisible, setPasswordVisible] = useState(false);
//     const [adminid, setAdminid] = useState('');
//     const [password, setPassword] = useState('');

//     const [isSubAdminActive, setIsSubAdminActive] = useState(false);
//     const [isAdminActive, setIsAdminActive] = useState(true);
//     const [email, setEmail] = useState('');

//     const navigate = useNavigate();

//     const togglePasswordVisibility = () => {
//         setPasswordVisible(!passwordVisible);
//     };

//     const handleAdminClick = () => {
//         setIsAdminActive(true);
//         setIsSubAdminActive(false);
//     };

//     const { ADMIN, PASSWORD } = config;
//     const handleLogin = async () => {
//         if (adminid && password) {
//             try {
//                 await signInWithEmailAndPassword(auth, adminid, password)
//                 if (adminid === ADMIN && password === PASSWORD) {
//                     alert("Login Successful");
//                     localStorage.setItem('admin', adminid);
//                     localStorage.setItem('password', password)
//                     localStorage.setItem('login', true);
//                     localStorage.setItem('role', 'admin');
//                     navigate('/process');
//                 } else {
//                     alert("Login Failed");
//                 }
//             }
//             catch (err) {
//                 console.log(err)
//             }
//         }
//         else {
//             alert("Please fill out both the fields")
//         }
//     }
//     return (
//         <div className="App">
//             <div style={backgroundContainerStyle} >
//                 {/* <div className='adminhead'>Admin Portal</div> */}

//                 <div className='d-flex flex-row align-items-top  w-100 mt-5 ' style={{ justifyContent: "space-evenly" }}>
//                     <div className='' style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "start" }}>
//                         <img src={logo} alt="Logo" className='' style={{ height: '300px', width: 'auto' }} />
//                         <div style={{ color: 'white', fontWeight: 'bold', fontSize: '40px' }}>"Nukkad Foods"</div>
//                         <div className='text-white'>Your Favorite Flavors, Right at Your Doorstep!"</div>
//                     </div>
//                     <div className="signin-card">
//                         <h1 className='fw-bold mt-3'>Sign In</h1>
//                         <h5 className='fw-bold mt-3'>Admin</h5>
//                         <div style={{ marginBlock: "70px" }}>
//                             <div className="input-group mb-4 " >
//                                 <input type="text" className="form-control" id="floatingInput" placeholder="Admin ID" value={adminid} onChange={(e) => { setAdminid(e.target.value) }} />

//                             </div>

//                             <div class="input-group mb-3 ">
//                                 <input type={passwordVisible ? "text" : "password"} value={password} class="form-control" id="floatingPassword"
//                                     placeholder="Password" aria-label="Example text with button addon" aria-describedby="button-addon1" onChange={(e) => { setPassword(e.target.value) }} />
//                                 <button class="btn btn-outline-secondary" type="button" id="button-addon1" onClick={togglePasswordVisibility}><span
//                                     className="password-toggle-icon"
//                                     onClick={togglePasswordVisibility}
//                                     // add color 
//                                     style={{ color: 'black' }}
//                                 >
//                                     <i className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
//                                 </span></button>
//                             </div>
//                             <button onClick={() => { handleLogin() }} className="login-button mt-5 fs-5">Login</button>

//                         </div>
//                     </div>
//                 </div>

//             </div>

//         </div>
//     );
// }

// export default SigninAdmin;


import React, { useState } from 'react';
import './ScreenCss/auth.css';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import image from "../Images/Bg.png"
import logo from "../Images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";



const backgroundContainerStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

function SigninAdmin() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [adminid, setAdminid] = useState('');
    const [password, setPassword] = useState('');

    const [isSubAdminActive, setIsSubAdminActive] = useState(false);
    const [isAdminActive, setIsAdminActive] = useState(true);
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    // const togglePasswordVisibility = () => {
    //     setPasswordVisible(!passwordVisible);
    // };

    const handleAdminClick = () => {
        setIsAdminActive(true);
        setIsSubAdminActive(false);
    };

    const handleSubAdminClick = () => {
        setIsAdminActive(false);
        setIsSubAdminActive(true);
        navigate("signinsubadmin")
    };

    const { ADMIN, PASSWORD } = config;
    const handleLogin = () => {
        if (adminid === ADMIN && password === PASSWORD) {
            // alert("Login Successful");
            localStorage.setItem('admin', adminid);
            localStorage.setItem('password', password)
            localStorage.setItem('login', true);
            localStorage.setItem('role', 'admin');
            navigate('/process');
        } else {
            alert("Login Failed");
        }
    }
    return (
        <div className="App">
            <div className='' style={backgroundContainerStyle} >
                {/* <div className='adminhead'>Admin Portal</div> */}

                <div className="flex flex-row items-start md:justify-between lg:justify-evenly justify-center container">
                    <div className="md:flex flex-col  items-center justify-start hidden">
                        <img src={logo} alt="Logo" className='' style={{ height: '300px', width: 'auto' }} />
                        <div style={{ color: 'white', fontWeight: 'bold', fontSize: '40px' }}>"Nukkad Foods"</div>
                        <div className='text-white'>Your Favorite Flavors, Right at Your Doorstep!"</div>
                    </div>
                    {/* <div className="signin-card">
                        <h1 className='fw-bold mt-3'>Sign In</h1>
                        <h5 className='fw-bold mt-3'>Admin</h5>
                        <div style={{ marginBlock: "70px" }}>
                            <div className="input-group mb-4 " >
                                <input type="text" className="form-control" id="floatingInput" placeholder="Admin ID" value={adminid} onChange={(e) => { setAdminid(e.target.value) }} />

                            </div>

                            <div class="input-group mb-3 ">
                                <input type={passwordVisible ? "text" : "password"} value={password} class="form-control" id="floatingPassword"
                                    placeholder="Password" aria-label="Example text with button addon" aria-describedby="button-addon1" onChange={(e) => { setPassword(e.target.value) }} />
                                <button class="btn btn-outline-secondary" type="button" id="button-addon1" onClick={togglePasswordVisibility}><span
                                    className="password-toggle-icon"
                                    onClick={togglePasswordVisibility}
                                    // add color 
                                    style={{ color: 'black' }}
                                >
                                    <i className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                </span></button>
                            </div>
                            <button onClick={() => { handleLogin() }} className="login-button mt-5 fs-5">Login</button>

                        </div>
                    </div> */}
                    <div className="md:p-[45.2px_63.27px] p-4 gap-[9.04px] rounded-[18.08px] bg-gradient-to-b from-black/15 to-black/15 backdrop-blur-[36px] shadow-[0px_0px_10.85px_0px_rgba(255,255,255,0.37)] opacity-1">
                        <div className='flex flex-col md:flex-row gap-[7.34px] justify-center font-semibold text-[11.54px] leading-[17.34px] font-poppins'>
                            <button
                                className={`py-[8.54px] px-14 rounded-[26.68px] shadow-[0px_8px_26.68px_0px_#DE5F8F1F] 
                        ${isAdminActive ? 'text-white bg-[#D00000]' : 'text-black bg-white'}`}
                                onClick={handleAdminClick}
                            >
                                Admin
                            </button>
                            <button
                                className={`py-[8.54px] px-10 rounded-[26.68px] shadow-[0px_8px_26.68px_0px_#DE5F8F1F] 
                        ${isSubAdminActive ? 'text-white bg-[#D00000]' : 'text-black bg-white'}`}
                                onClick={handleSubAdminClick}
                            >
                                SubAdmin
                            </button>
                        </div>

                        <div className='mt-14 mb-7'>
                            <p className="font-zen text-[12.8px] font-normal leading-[22.53px] text-white decoration-skip-ink-none">
                                LET'S GET YOU STARTED
                            </p>

                            <h1 className="font-zen text-[25px] font-medium leading-[44px] text-white decoration-skip-ink-none">ADMIN</h1>
                        </div>

                        <div className="relative mb-6 font-zen">
                            <input
                                type="email"
                                value={adminid}
                                onChange={(e) => { setAdminid(e.target.value) }}
                                className="w-full px-4 py-2 text-gray-400 bg-transparent border border-[#BDBDBD] rounded-md outline-none focus:border-gray-400 peer" />
                            <label
                                className={`absolute left-4 text-gray-200 text-sm -top-3 transition-all bg-[#4533ab] px-2 rounded ${adminid ? '' : 'top-2 text-base peer-focus:-top-3 peer-focus:text-sm'}`}>
                                Email
                            </label>
                        </div>

                        <div className="relative mb-6 font-zen">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                                className="w-full px-4 py-2 text-gray-400 bg-transparent border border-[#BDBDBD] rounded-md outline-none focus:border-purple-500 peer" />
                            <label
                                className={`absolute left-4 text-gray-200 text-sm -top-3 transition-all  bg-[#4533ab] px-2 rounded ${password ? '' : 'peer-focus:-top-3 peer-focus:text-sm text-base top-2'}`}>
                                Password
                            </label>
                            <button
                                type="button"
                                className="absolute right-4 top-1/2 transform -translate-y-1/2"
                                onClick={() => setPasswordVisible(!passwordVisible)}>
                                <FontAwesomeIcon
                                    icon={passwordVisible ? faEyeSlash : faEye}
                                    className="text-gray-400" />
                            </button>
                        </div>

                        <button onClick={() => { handleLogin() }} className="w-full font-poppins text-[14.8px] leading-[22.53px] py-3 text-black bg-white rounded-md font-semibold transition-all duration-300 transform hover:bg-black hover:text-white hover:font-bold hover:scale-105">
                            GET STARTED
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SigninAdmin;